import React, { useRef, useState, useCallback, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useConfig } from '../ConfigProvider';
import cloneDeep from 'lodash/cloneDeep';
import FileItem from './FileItem';
import Button from '../Buttons';
import CloseButton from '../CloseButton';
import Notification from '../Notification';
import toast from '../toast';

const filesToArray = (files) => Object.keys(files).map((key) => files[key]);

const Upload = React.forwardRef((props, ref) => {
	const {
		accept,
		beforeUpload,
		disabled,
		draggable,
		fileList,
		multiple,
		onChange,
		onFileRemove,
		showList,
		tip,
		uploadLimit,
		children,
		className,
		field,
		form,
		shouldLimitSize,
		allowedSize,
		...rest
	} = props;

	const fileInputField = useRef(null);
	const [files, setFiles] = useState(fileList);
	const [dragOver, setDragOver] = useState(false);
	const [sizeWarning, setSizeWarning] = useState(false);
	const [hasFiles, setHasFiles] = useState(fileList.length > 0);

	const { themeColor, primaryColorLevel } = useConfig();

	useEffect(() => {
		setFiles(fileList);
		setHasFiles(fileList.length > 0);
	}, [fileList]);

	const triggerMessage = (msg) => {
		toast.push(
			<Notification type="danger" duration={2000}>
				{msg || 'Upload Failed!'}
			</Notification>,
			{ placement: 'top-center' }
		);
	};

	const pushFile = (newFiles, file) => {
		for (let f of newFiles) {
			file.push(f);
		}
		return file;
	};

	const addNewFiles = (newFiles) => {
		let file = cloneDeep(files);
		if (typeof uploadLimit === 'number' && uploadLimit !== 0) {
			if (file.length >= uploadLimit) {
				if (uploadLimit === 1) {
					file.shift();
					file = pushFile(newFiles, file);
				}
				return filesToArray({ ...file });
			}
		}
		file = pushFile(newFiles, file);
		return filesToArray({ ...file });
	};

	const onNewFileUpload = (e) => {
		const { files: newFiles } = e.target;
		if (shouldLimitSize) {
			let file = e.target.files[0];
			if (file) {
				const maxSizeInBytes = allowedSize * 1024 * 1024;
				if (file.size > maxSizeInBytes) {
					e.target.value = null;
					setSizeWarning(true);
					return;
				} else {
					setSizeWarning(false);
				}
			}
		}
		let result = true;

		if (beforeUpload) {
			result = beforeUpload(newFiles, files);

			if (result === false) {
				triggerMessage();
				return;
			}

			if (typeof result === 'string' && result.length > 0) {
				triggerMessage(result);
				return;
			}
		}

		if (result) {
			let updatedFiles = addNewFiles(newFiles);
			setFiles(updatedFiles);
			setHasFiles(updatedFiles.length > 0);  // Update the hasFiles state
			onChange?.(updatedFiles, files);
		}
	};

	const removeFile = (fileIndex) => {
		const deletedFileList = files.filter((_, index) => index !== fileIndex);
		setFiles(deletedFileList);
		setHasFiles(deletedFileList.length > 0);  // Update the hasFiles state
		onFileRemove?.(deletedFileList);
	};

	const triggerUpload = (e) => {
		if (!disabled && !hasFiles) { // Only trigger if no files are present
			fileInputField.current?.click();
		}
		e.stopPropagation();
	};

	const renderChildren = () => {
		if (!draggable && !children) {
			return <Button disabled={disabled} onClick={(e) => e.preventDefault()}>Upload</Button>;
		}

		if (draggable && !children) {
			return <span>Choose a file or drag and drop here</span>;
		}

		return children;
	};

	const handleDragLeave = useCallback(() => {
		if (draggable) {
			setDragOver(false);
		}
	}, [draggable]);

	const handleDragOver = useCallback((e) => {
		e.preventDefault();
		if (draggable && !disabled) {
			setDragOver(true);
		}
	}, [draggable, disabled]);

	const handleDrop = useCallback((e) => {
		e.preventDefault();
		setDragOver(false);
		const { files: newFiles } = e.dataTransfer;
		if (newFiles.length > 0) {
			onNewFileUpload({ target: { files: newFiles } });
		}
	}, [onNewFileUpload]);

	const draggableProp = {
		onDragLeave: handleDragLeave,
		onDragOver: handleDragOver,
		onDrop: handleDrop,
	};

	const draggableEventFeedbackClass = `border-${themeColor}-${primaryColorLevel}`;

	const uploadClass = classNames(
		'upload',
		draggable && 'upload-draggable',
		(draggable && !disabled) && `hover:${draggableEventFeedbackClass}`,
		(draggable && disabled) && 'disabled',
		dragOver && draggableEventFeedbackClass,
		className
	);

	const uploadInputClass = classNames('upload-input', draggable && 'draggable');

	return (
		<>
			<div
				ref={ref}
				className={uploadClass}
				{...(draggable ? draggableProp : { onClick: triggerUpload })}
				{...rest}
			>
				<input
					className={uploadInputClass}
					type="file"
					ref={fileInputField}
					onChange={onNewFileUpload}
					disabled={disabled}
					multiple={multiple}
					accept={accept}
					title=""
					value=""
					{...field}
					{...rest}
				/>
				{renderChildren()}
			</div>
			{tip}
			{showList && (
				<div className="upload-file-list">
					{files.map((file, index) => (
						<FileItem file={file} key={file.name + index} sizeWarning={sizeWarning}>
							<CloseButton onClick={() => removeFile(index)} className="upload-file-remove" style={{ cursor: 'pointer' , position: 'absolute', right: '0.5rem',marginBottom: '4.0rem'}} />
						</FileItem>
					))}
					{sizeWarning && <p className="text-red-500">File size limit exceeded.</p>}
				</div>
			)}
		</>
	);
});

Upload.propTypes = {
	uploadLimit: PropTypes.number,
	draggable: PropTypes.bool,
	disabled: PropTypes.bool,
	showList: PropTypes.bool,
	multiple: PropTypes.bool,
	accept: PropTypes.string,
	tip: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.node
	]),
	allowedSize: PropTypes.number
};

Upload.defaultProps = {
	draggable: false,
	showList: true,
	disabled: false,
	fileList: [],
	allowedSize: 2
};

export default Upload;
