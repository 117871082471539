export const initialState = {
  documentRequest: {
    id: 1,
    requestDate: "2022-02-12",
    requestedByUser: 123,
    doctorFacilityId: null,
    insuranceCompanyId: null,
    attorneyOfficeId: null,
    patientFirstName: "John",
    patientMiddleName: "M",
    patientLastName: "Doe",
    DOB: "2000-01-01",
    last4SSN: "1234",
    mrn: "MRN001",
    clientRefId: "CR001",
    paymentMode: 1,
    requestStatusId: 1,
    updatedBy: "Jane",
    updatedAt: "2022-02-12T10:00:00.000Z",
    xRayBreakdown: [
      {
        id: 1,
        xrayExamId: 1,
        xrayDate: "2022-01-01",
        bodyPartId: 1,
        quantity: 1,
        unitPrice: "50.00",
        userRequestApproved: true,
        updatedBy: "admin",
        updatedAt: "2023-02-25T23:55:29.000Z",
      },
      {
        id: 2,
        xrayExamId: 1,
        xrayDate: "2022-01-01",
        bodyPartId: 2,
        quantity: 2,
        unitPrice: "100.00",
        userRequestApproved: true,
        updatedBy: "admin",
        updatedAt: "2023-02-25T23:55:29.000Z",
      },
    ],
    statusHistory: [
      {
        id: 1,
        DocumentRequestOrderId: 1,
        StatusId: 1,
        UpdatedBy: "Alice",
        UpdatedAt: "2022-02-12T10:00:00.000Z",
        requeststatus: {
          id: 1,
          Description: "Order Received",
        },
      },
      {
        id: 2,
        DocumentRequestOrderId: 1,
        StatusId: 2,
        UpdatedBy: "Bob",
        UpdatedAt: "2022-02-13T14:30:00.000Z",
        requeststatus: {
          id: 2,
          Description: "In Process",
        },
      },
      {
        id: 3,
        DocumentRequestOrderId: 1,
        StatusId: 3,
        UpdatedBy: "Charlie",
        UpdatedAt: "2022-02-14T08:45:00.000Z",
        requeststatus: {
          id: 3,
          Description: "Waiting for doctor to review Billing records.",
        },
      },
    ],
    userFiles: [],
    adminFiles: [],
  },
  invoiceInfo: [],
  xrayExamBreakdownIds:[],
  configurationData:{},
  paymentHistory: [],
  doctorFacility: {},
  insuranceCompany: {},
  attorneyOffice:{},
  basicUserList: [],
  requestingPartyDetails: {},
  invoiceServices: [],
  universalInvoiceServices: [],
  hasInvoiceServicesUpdated: false,
  hasUniversalInvoiceServicesUpdated: false,
  hasInvoiceGenerated: false,
  downloadedFiles: {},
  invoice: {
    id: 1000,
    documentRequestOrderId: 1,
    invoiceDate: "2022-01-01T10:00:00.000Z",
    billedAmount: "100.00",
    discountAmount: "10.00",
    specialMessage: "10% discount for loyal customers",
    paymentReceiptId: 1,
    receivedAmount: "90.00",
    amountDue: "0.00",
    status: "Paid",
    updatedBy: "John Doe",
    updatedAt: "2023-02-25T23:14:32.000Z",
    invoiceItems: [
      {
        id: 100,
        invoiceId: 1000,
        serviceFeeId: 200,
        unitPrice: 10.09,
        quantity: 5,
        subtotal: 50.45,
        tax: true,
        feeDescription: {
          id: 1,
          description: "Check processing Fee",
        },
        ParentName: "P1",
        UnitType: '',
        Units: 0
      },
      {
        id: 101,
        invoiceId: 1000,
        serviceFeeId: 201,
        unitPrice: 5.0,
        quantity: 2,
        subtotal: 10.0,
        tax: false,
        feeDescription: {
          id: 2,
          description: "Copy Fee",
        },
        ParentName: "P2",
        UnitType: '',
        Units: 0
      },
    ],
  },
  metadata: {
    recordTypes: [],
    requestTypes: [],
    doctorFacilities: [],
    insuranceCompanies: [],
    attorneyOffices: [],
    internalStatusDomain: [],
    statusDomain: [],
    feeTypes: [],
    xrayBodyParts: [
    ],

    //xray
    requestXrayPayment: [
      {
        id: 1,
        value: 1,
        description: "Pending",
        label: "Pending",
      },
      {
        id: 2,
        value: 2,
        description: "Paid",
        label: "Paid",
      },
    ],

    requestXrayPanelState: 1, //1 = no data exist and new data can be added //2 = waiting for user to pay for files //3 = user paid for required files and documents can be uploaded

    requestStatusTypes: [
    ],
  },
  orderDetailsData: {},
  support: [
    {
      id: "",
      documentRequestOrderId: "",
      userId: "",
      message: "",
      sentOn: "",
      updatedBy: null,
      updatedAt: "",
      userid: 1,
      user: {
        firstName: "",
        lastName: "",
      },
    },
  ],
  notes: [
    {
      id: "",
      documentId: "",
      msg: "",
      orgId: "",
      createdBy: null,
      createdAt: "",
      user: {
        firstName: "",
        lastName: "",
        middleName: "",
        email: ""
      },
    },
  ],
  apiPayload: {
    documentRequestStatusHistory: [],

    documentRequestXrayBreakdown: [],

    documentRequestUpdatedInvoiceData: [],

    documentRequestInvoiceDetails: {}
  },
  loading: {
    doctorFacility: false,
    insuranceCompany: false,
    attorneyOffice: false,
    requestDetails: false,
    location: false,
    statusHistory: false,
    invoice: false,
    invoiceServices: false,
    universalInvoiceServices: false,
    invoiceInfo: false,
    paymentHistory: false,
    updateInvoice: false,
    xRayBreakdown: false,
    userFiles: false,
    adminFiles: false,
    files: false,
    createCheckPayment: false,
    createWriteOffPayment: false,
    support: false,
    notes: false,
    sendMessage: false,
    generateInvoice: false,
    requestStatusTypes: false,
    metadata: {
      requestStatusTypes: false,
      xRayBodyParts: false,
      feeTypes: false,
      doctorFacilities: false,
      insuranceCompanies: false,
      attorneyOffices: false
    },
    updates: {
      statusHistory: false,
      xRayBreakdown: false,
    },
  },
  errors: {
    doctorFacility: null,
    insuranceCompany: null,
    attorneyOffice: null,
    invoice: null,
    invoiceServices: null,
    universalInvoiceServices: null,
    invoiceInfo: null,
    paymentHistory: null,
    updateInvoice: null,
    requestDetails: null,
    statusHistory: null,
    xRayBreakdown: null,
    userFiles: null,
    adminFiles: null,
    createCheckPayment: null,
    support: null,
    notes: null,
    createWriteOffPayment: null,
    sendMessage: null,
    generateInvoice: null,
    metadata: {
      requestStatusTypes: null,
      xRayBreakdownPayments: null,
      feeTypes: null,
      doctorFacilities: null,
      insuranceCompanies: null,
      attorneyOffices: null
    },
    updates: {
      statusHistory: null,
      xRayBreakdown: null,
    },
  },
  ui: {
    requestXrayDialogOpen: false,
    requestFilesDialogOpen: false,
    requestZipFilesDialogOpen: false,
    requestXrayDialogMode: null,
    editableDocumentRequestXrayBreakdownId: -1,
    requestStatusDialogOpen: false,
    requestStatusDialogMode: null,
    editableDocumentRequestStatusHistoryId: -1,
    editableInvoiceInfoId: -1,
    isWriteOffDialogOpen: false,
    isRecordPaymentDialogOpen: false,
    isPlatFormFee:false,
    isCnrDialogOpen: false,
    isPaymentHistoryOpen: false,
  },
  modifications: {
    statusHistory: false,
    xRayBreakdown: false,
    requestInvoice: false,
    orderDetails: false
  },

  serverResponse: {
    createCheckPayment: null,
    createWriteOffPayment: null,
    updateOrderDetails: null,
    generateInvoice: null
  }
};
